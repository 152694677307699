.wrapper_b2b_clients_request {
    background: #F9F9F9;
    padding: 52px 100px;
    margin-bottom: 20px;
    display: grid;
    grid-gap: 0 20px;
}

.wrapper_b2b_clients_request .wrapper_description,
.wrapper_b2b_clients_request .wrapper_title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.02em;
    grid-column: 1/-1;
    color: #000
}

.wrapper_b2b_clients_request .wrapper_description {
    font-size: 16px;
    line-height: 22px;
}

.wrapper_b2b_clients_request .wrapper_b2b_clients_request_img {
    display: grid;
    align-content: center;
    justify-content: center;
}

.wrapper_b2b_clients_request .wrapper_b2b_clients_request_img img {
    object-fit: contain;
    width: auto;
    height: 230px;
}

.wrapper_b2b_clients_request form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper_b2b_clients_request form .wrapper_submit_request {
    flex-grow: 1;
    margin-right: 20px;
}

.wrapper_b2b_clients_request form .wrapper_submit_request button {
    padding: 8px 0;
}

.wrapper_b2b_clients_request form .wrapper_every_input {
    margin-right: 20px;
    width: 100%;
}

.wrapper_b2b_clients_request form .wrapper_every_input input {
    padding: 8px 0;
}

.wrapper_custom_popup {
    padding: 40px 80px;
    grid-column: 1 / -1;
}

.wrapper_custom_popup .wrapper_title {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #333
}

.wrapper_custom_popup .wrapper_submit {
    grid-template-columns: 1fr !important;
    margin-top: 20px;
}

@media (max-width: 1300px) {
    .wrapper_b2b_clients_request {
        padding: 40px 60px;
        grid-template-columns: 1fr 1fr;
    }

    .wrapper_b2b_clients_request .wrapper_b2b_clients_request_img img {
        height: 180px;
    }
}

@media (max-width: 1150px) {
    .wrapper_b2b_clients_request {
        grid-template-columns: 40% 1fr;
    }

    .wrapper_b2b_clients_request .wrapper_b2b_clients_request_img img {
        height: 200px;
    }
}

@media (max-width: 768px) {
    .wrapper_b2b_clients_request {
        grid-template-columns: 1fr;
    }

    .wrapper_b2b_clients_request .wrapper_b2b_clients_request_img {
        grid-row: 1;
    }

    .wrapper_b2b_clients_request .wrapper_description,
    .wrapper_b2b_clients_request .wrapper_title {
        text-align: center;
    }

    .wrapper_b2b_clients_request .wrapper_b2b_clients_request_img img {
        height: 159px;
    }
}

@media (max-width: 480px) {
    .wrapper_b2b_clients_request {
        padding: 40px 20px;
    }

    .wrapper_b2b_clients_request .wrapper_title {
        font-size: 22px;
    }

    .wrapper_b2b_clients_request .wrapper_description {
        font-size: 14px;
    }

    .wrapper_b2b_clients_request .wrapper_b2b_clients_request_img img {
        height: 120px;
    }
}