.contacts-form {
    display: flex;
    margin-bottom: 8vh;
}

.form-block {
    background: #F9F9F9;
    padding: 7.5vh 5.5vw 8.5vh;
}

.form-block h2 {
    font-family: 'Playfair Display', sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    margin: 0 0 20px 0;
    color: #333333;
}

.form-block p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin: 0 0 3rem 0;
}

.form-block form {
    width: 100%;
}

.form-block input, .form-block input::placeholder, .form-block textarea::placeholder{
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
}

.form-block input {
    width: 100%;
    padding: 8px 0;
    margin-bottom: 20px;
}

.form-block textarea {
    width: 100%;
    background: transparent;
    border-color: #333;
    border-width: 0 0 1px 0;
    margin-bottom: 20px;
    resize: none;
}

.form-block span {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
}

.form-block a {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #C69C6D;
    border: 1px solid #C69C6D;
    box-sizing: border-box;
    padding: 19px 0;
    width: 100%;
    background: transparent;
    margin-bottom: 15px;
    cursor: pointer;
}

.contacts-form-links {
    padding-left: 16.6%;
}

.contacts-form-links span {
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #828282;
    margin-bottom: .5rem;
}

.contacts-form-links a {
    display: inline-block;
    font-family: 'Playfair Display', sans-serif;
    font-weight: normal;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
    margin-bottom: 2rem;
}

.w-50 {
    width: 50%;
}

input[type="checkbox"] {
    display: none;
}

.checkbox {
    cursor: pointer;
}

.checkbox input + span:not(:empty) {
    padding-left: 30px;
}

.checkbox input + span {
    display: block;
    position: relative;
}

.checkbox input + span:before,
.checkbox input + span:after {
    content: "";
    width: 17px;
    height: 17px;
    display: block;
    border-radius: 50%;
    left: 0;
    top: 0;
    position: absolute;
}

.checkbox input + span:before {
    background: #d1d7e3;
    transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}

.checkbox input + span:after {
    background: #fff;
    transform: scale(0.8);
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}

.checkbox-active input + span:before {
    -webkit-transform: scale(1.04);
    transform: scale(1.04);
    background: #C69C6D;
}

.checkbox-active input + span:after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
}

.checkbox:hover input + span:before {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
}

.checkbox:hover input + span:after {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
}

.checkbox-active:hover input + span:after {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
}

.map-container {
    height: 285px;
    width: 100%;
}
.map-wrapper > div {
    position: static !important;
    margin-bottom: 7vh;
}

.my-auto {
    margin: auto 0;
}

@media (max-width: 1025px) {
    .form-block {
        padding: 7.5vh 5.5vw 8.5vh;
    }

    .contacts-form-links {
        padding-left: 1.5rem;
    }
}

@media (max-width: 769px) {
    .form-block {
        padding: 7.5vh 11vw 8.5vh;
        margin-top: 20px;
    }

    .contacts-form-links {
        padding-left: 0;
    }

    .order-1 {
        order: 2;
    }
    .order-2 {
        order: 1;
    }

    .contacts-form {
        flex-wrap: wrap;
    }
    .w-50 {
        width: 100%;
    }

    .contacts-form-links-tablets {
        display: flex;
    }

    .w-50-tablets {
        width: 50%;
    }

    .form-block p {
        margin-bottom: 2rem;
    }

    .form-block input {
        padding: 12px 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 541px) {

    .contacts-form-links-tablets {
        display: flex;
        flex-wrap: wrap;
    }

    .w-50-tablets {
        width: 100%;
    }

    .form-block {
        padding: 7.5vh 9vw 8.5vh;
        margin-top: 20px;
    }
}

@media (max-width: 321px) {
    /* todo: */

    /*.contacts-form-links a {*/
    /*    font-size: 22px;*/
    /*    line-height: 29px;*/
    /*    margin-bottom: 2.5rem;*/
    /*}*/

    /*.form-block {*/
    /*    padding: 2.5rem 1rem 2.25rem;*/
    /*    margin-top: 20px;*/
    /*}*/

    /*.form-block h2 {*/
    /*    font-size: 24px;*/
    /*    line-height: 32px;*/
    /*    margin: 0 0 14px 0;*/
    /*}*/

    /*.form-block p {*/
    /*    font-size: 14px;*/
    /*    line-height: 19px;*/
    /*}*/
}
